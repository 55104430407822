import athenaConfig from '../config';

export function footerButton(clientId: string | null) {
  const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        const button = document.querySelector('#athena-footer > div > button');
        if (button) {
          const athenaFooterButton = document.createElement('button');
          athenaFooterButton.className = 'fe_c_button fe_c_button--tertiary fe_c_button--small';
          athenaFooterButton.setAttribute('aria-disabled', 'false');
          athenaFooterButton.setAttribute('type', 'button');
          athenaFooterButton.innerHTML = '<div class="fe_c_button__content fe_c_button__content--small">' +
            '<span class="fe_c_button__text fe_c_button__text--small fe_c_button__text--tertiary">Choose a different method</span>' +
            '</div>';
          button.replaceWith(athenaFooterButton);
          if (clientId && athenaConfig?.clientIdMappingManageProfileRedirect?.[clientId] !== 'undefined') {
            const link = athenaConfig?.clientIdMappingManageProfileRedirect?.[clientId];
            const backButton = document.evaluate("//button/div/span[text()='Choose a different method']", document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
            backButton?.addEventListener('click', () => {
              window.location.href = link;
            });
          }
          observer.disconnect();
        }
      }
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });
}
export function footerButtonSQ(clientId: string | null) {
  const athenaFooter = jQueryCourage(
    '<div id="athena-footer">' +
      '<div class="fe_c_root fe_f_all">' +
        '<div class="fe_u_margin--bottom-large fe_u_margin--top-small fe_u_font-size--small fe_u_text-align--left">' +
          '<p class="fe_u_color--muted">Please contact your administrator if you require assistance setting up or removing a factor.</p>' +
        '</div>' +
        '<button class="fe_c_button fe_c_button--tertiary fe_c_button--small" aria-disabled="false" type="button">' +
          '<div class="fe_c_button__content fe_c_button__content--small">' +
            '<span class="fe_c_button__text fe_c_button__text--small fe_c_button__text--tertiary">Choose a different method</span>' +
          '</div>' +
        '</button>' +
        '<br>' +
      '</div>' +
    '</div>'
  );

  const authFooter = jQueryCourage('.auth-footer');

  athenaFooter.insertAfter(authFooter);

  if (clientId && athenaConfig?.clientIdMappingManageProfileRedirect?.[clientId] !== 'undefined') {
    const link = athenaConfig?.clientIdMappingManageProfileRedirect?.[clientId];
    const button = document.evaluate("//button/div/span[text()='Choose a different method']", document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    button?.addEventListener('click', () => {
      window.location.href = link;
    });
  }
}
