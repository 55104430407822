import { CONTACT_ADMIN_TEXT, PATIENT_MFA_FACTOR_SETUP_CUSTOM_EVENTS } from '../../constants';
import BaseForgeCustomizer from '../base-forge';
import config from '../../config';

export default abstract class FactorEnrollCustomizerBase extends BaseForgeCustomizer {
  static manageFactorValue: string | null = null;
  static clientId: string | null = null;
  abstract forgifyFactors(): void;

  protected forgifyController() {
    super.forgifyController();
    this.forgifyFactors();
  }

  footerText = CONTACT_ADMIN_TEXT;
  headerText = 'Set up multifactor authentication';

  async shouldCustomize() {
    let buttonFound = false;
    let retries = 0;
    const maxRetries = 3;
    function waitForElement() {
      const $element = jQueryCourage('.fe_c_button--secondary');
      if ($element.length > 0 && !buttonFound) {
        $element.trigger('click');
        buttonFound = true;
      }
      else if (!buttonFound && retries < maxRetries) {
        retries++;
        setTimeout(waitForElement, 1000);
      }
    }

    if (config.patientMFAEnabled
      && FactorEnrollCustomizerBase.manageFactorValue
      && FactorEnrollCustomizerBase.clientId
      && config?.clientIdMappingManageProfileRedirect?.[FactorEnrollCustomizerBase.clientId]!== 'undefined'
    ) {
      waitForElement();
    }

    return forgify && [ 'enroll-choices' ].includes(this.context.controller);
  }
}

document.addEventListener(PATIENT_MFA_FACTOR_SETUP_CUSTOM_EVENTS.manageFactorWorkflow, (e) => {
  FactorEnrollCustomizerBase.manageFactorValue = (e as CustomEvent).detail.manageFactorValue;
  FactorEnrollCustomizerBase.clientId = (e as CustomEvent).detail.clientId;
});

