import athenaConfig from './lib/config';
import logger from './logger';
import AmplitudeUtils from './lib/utils/amplitude-utils';
import customizeController from './lib/customizers';
import { APP_TYPES, ERROR_MESSAGES, I18N_MAPPING } from './lib/constants';
import { applyContentHidingForCustomization, getRawAppName, isRequestFromSamlClient } from './lib/utils/widget-facade';
import { getAppConfigForApp } from './lib/utils/app-config-helper';
import { transformUsername } from './lib/utils/user-utils';

const { portalClientId, manageProfileUrl } = athenaConfig;

const aosw = {
  getTitleForApp: (context: any) => {
    if (!context) return 'Sign In';
    const isSpanish = config?.language?.includes('es');
    const appName = getRawAppName(context);
    if (appName) {
      if (portalClientId === context.target.clientId) {
        if (isSpanish) {
          return 'Tu Portal del Paciente';
        }
        else return 'Your Patient Portal';
      }
      else {
        return appName;
      }
    }
    else return 'Sign In';
  },

  getConsentRequiredText: (manageProfileUrl: string, appName: string | undefined = undefined) => {
    // 0 is replaced okta-widget using i18n and shouldn't be passed while setting up through i18n
    // with OIE, we need to set this text dynamically, hence, the final message should be populated with app name
    appName = appName || '{0}';
    const disclaimerText = `
    <p align="center">
    <b>${appName}</b> is asking to access parts of your medical record:
    </p>
    <br><br>
    <p align="left" class="fe_f_all fe_c_heading--subsection" style="font-family: 'Source Sans Pro', arial, sans-serif" font-size: 18px;>
    <b>Take control of your health data.</b></p>
    <br>
    <p align="left" class="fe_f_all" style="font-family: 'Source Sans Pro', arial, sans-serif" font-size: 16px;>
    We know having control over your health is important, which is why athenahealth allows you to choose which apps you use to interact with your health data.</p>
    <br>
    <p align="left" class="fe_f_all" style="font-family: 'Source Sans Pro', arial, sans-serif" font-size: 16px;>
    Please note you are responsible for understanding how apps will use your data and grant the permissions below at your own risk. Visit <a href="https://www.athenahealth.com/patient-login/faq" data-se="privacy-link" style="color:#0366d6" class="link js-help-link" target="_blank">our patient FAQs</a> to learn more about managing access to your records.</p>
    <br><hr style="border-top: 1px solid #d3d4d4; display:block"><br>
    <p align="left" class="fe_f_all fe_c_heading--subsection" style="font-family: 'Source Sans Pro', arial, sans-serif" font-size: 18px;>
    <b>Grant permissions to this app</b></p>
    <br>
    <p align="left" class="fe_f_all" style="font-family: 'Source Sans Pro', arial, sans-serif" font-size: 16px;>
    You can uncheck any permissions you don’t want to share with this app below. You can change these choices later in your <a href="${manageProfileUrl}" data-se="profile-link" style="color:#0366d6" class="link js-help-link" target="_blank">athenahealth profile</a> privacy settings.</p>
    `;
    const disclaimerTextEs = `
    <p align="center">
    <b>${appName}</b> está solicitando acceder a partes de su expediente médico:
    </p>
    <br><br>
    <p align="left" class="fe_f_all fe_c_heading--subsection" style="font-family: 'Source Sans Pro', arial, sans-serif" font-size: 18px;>
    <b>Tome el control de sus datos de salud.</b></p>
    <br>
    <p align="left" class="fe_f_all" style="font-family: 'Source Sans Pro', arial, sans-serif" font-size: 16px;>
    Sabemos que tener control sobre sus datos de salud es importante, por eso athenahealth le permite elegir qué aplicaciones usa para interactuar con sus datos de salud.</p>
    <br>
    <p align="left" class="fe_f_all" style="font-family: 'Source Sans Pro', arial, sans-serif" font-size: 16px;>
    Tenga en cuenta que usted es responsable de comprender cómo las aplicaciones utilizarán sus datos y otorgue los permisos a continuación bajo su propio riesgo. Visita nuestras <a href="https://www.athenahealth.com/patient-login/faq" data-se="privacy-link" style="color:#0366d6" class="link js-help-link" target="_blank">preguntas frecuentes de los pacientes</a> para obtener más información sobre la gestión del acceso a tus historiales.</p>
    <br><hr style="border-top: 1px solid #d3d4d4; display:block"><br>
    <p align="left" class="fe_f_all fe_c_heading--subsection" style="font-family: 'Source Sans Pro', arial, sans-serif" font-size: 18px;>
    <b>Otorgar permisos a esta aplicación</b></p>
    <br>
    <p align="left" class="fe_f_all" style="font-family: 'Source Sans Pro', arial, sans-serif" font-size: 16px;>
    Puede desmarcar los permisos que no desea compartir con esta aplicación a continuación. Puede cambiar estas opciones más adelante en la configuración de privacidad de su <a href="${manageProfileUrl}" data-se="profile-link" style="color:#0366d6" class="link js-help-link" target="_blank">perfil de athenahealth</a>.</p>
    `;
    if (config?.language?.includes('es')) {
      return disclaimerTextEs;
    }
    else {
      return disclaimerText;
    }
  },

  getConsentRequiredDescription: () => {
    return ' ';
  },

  getSkipConsentButtonText: () => {
    if (config?.language?.includes('es')) {
      return 'Disminución';
    }
    else {
      return 'Decline';
    }
  },

  getAllowConsentButtonText: () => {
    if (config?.language?.includes('es')) {
      return 'Permitir Acceso';
    }
    else {
      return 'Allow Access';
    }
  },

  flatten: (jsonObject: Record<string, any>) => {
    const result: Record<string, any> = {};
    for (const key in jsonObject) {
      if (jsonObject[key] && typeof jsonObject[key] === 'object' && !(jsonObject[key] instanceof Array)) {
        const innerObject = aosw.flatten(jsonObject[key]);
        for (const innerKey in innerObject) {
          result[`${key}.${innerKey}`] = innerObject[innerKey];
        }
      }
      else {
        result[key] = jsonObject[key];
      }
    }
    return result;
  },

  getStateLanguage: (requestContext: any) => {
    try {
      if (requestContext.authentication.request.state) {
        const state = JSON.parse(requestContext.authentication.request.state);
        return state.LANGUAGE;
      }
    }
    catch (error) {
      //Ignore errors if JSON parsing fails
    }
  },
  getIdpDiscoveryFeatureValue: (appConfig: any, requestContext: any) => {
    if (Object.keys(appConfig).length) {
      return (
        [ APP_TYPES.ATHENANET_USER, APP_TYPES.ATHENISTA ].includes(appConfig.appType)
        &&
        !(appConfig.disableIdpDiscovery)
      );
    }
    // TODO Get rid of this condition once saml builder tool supports app config creation
    return isRequestFromSamlClient(requestContext);
  },
  customizeSignInWidget,
  setupHooks,
};

function customizeSignInWidget(config: any) {
  const requestContext = global.OktaUtil.getRequestContext();
  const loginContainer = document.getElementById('okta-login-container');
  if (loginContainer) {
    // a top margin for applogin-container we are hiding
    loginContainer.style.marginTop = '0px';
  }
  if (!config) {
    return;
  }
  try {
    //Disable security beacon when forgify is enabled to avoid overflow in primary-auth
    if (forgify) {
      config.features.securityImage = false;
    }
    // set username transformer function
    const appConfig = getAppConfigForApp({ requestContext, appConfigs });
    config.transformUsername = transformUsername;
    config.features = { ...config.features, idpDiscovery: aosw.getIdpDiscoveryFeatureValue(appConfig, requestContext) };
    let language = config.language;

    const isPatientApp = appConfig.appType === APP_TYPES.PATIENT;

    if (!appConfig?.i18n?.[language]) {
      config.i18n[language] = config.i18n[language] || {};
      config.i18n[language]['primaryauth.title'] = aosw.getTitleForApp(requestContext);
      if (isPatientApp) {
        config.i18n[language]['consent.required.text'] = aosw.getConsentRequiredText(manageProfileUrl);
        config.i18n[language]['oie.consent.scopes.granular.title'] = aosw.getConsentRequiredText(manageProfileUrl);
        config.i18n[language]['consent.required.description'] = aosw.getConsentRequiredDescription();
        config.i18n[language]['oie.consent.scopes.granular.description'] = aosw.getConsentRequiredDescription();
        config.i18n[language]['consent.required.cancelButton'] = aosw.getSkipConsentButtonText();
        config.i18n[language]['consent.required.consentButton'] = aosw.getAllowConsentButtonText();
      }
      config.i18n[language] = { ...I18N_MAPPING[language], ...config.i18n[language] };
      return;
    }

    config.language = language = aosw.getStateLanguage(requestContext) || config.language;

    config.i18n[language] = {};
    if (appConfig?.i18n[language]) {
      config.i18n[language] = aosw.flatten(appConfig.i18n[language]);
    }

    config.i18n[language] = { ...I18N_MAPPING[language], ...config.i18n[language], 'primaryauth.title': aosw.getTitleForApp(requestContext) };

    if (isPatientApp) {
      if (!config.i18n[language]['consent.required.text']) {
        config.i18n[language]['consent.required.text'] = aosw.getConsentRequiredText(manageProfileUrl);
        config.i18n[language]['oie.consent.scopes.granular.title'] = aosw.getConsentRequiredText(manageProfileUrl);
      }
      if (!config.i18n[language]['consent.required.description']) {
        config.i18n[language]['consent.required.description'] = aosw.getConsentRequiredDescription();
        config.i18n[language]['oie.consent.scopes.granular.description'] = aosw.getConsentRequiredDescription();
      }
      if (!config.i18n[language]['consent.required.cancelButton']) {
        config.i18n[language]['consent.required.cancelButton'] = aosw.getSkipConsentButtonText();
      }
      if (!config.i18n[language]['consent.required.consentButton']) {
        config.i18n[language]['consent.required.consentButton'] = aosw.getAllowConsentButtonText();
      }
    }

  }
  catch (error) {
    logger.log(error);
  }
}

function setupHooks(widget: any) {
  const requestContext = global.OktaUtil.getRequestContext();
  widget.on('ready', function () {
    applyContentHidingForCustomization();
    // The Widget is ready for user input
    AmplitudeUtils.logCustomEvent('Landing', { });
    if (window.innerWidth <= 1023) {
      AmplitudeUtils.logCustomEvent('SuppressMarketingContent', { });
    }
  });

  widget.on('afterRender', async function (context: any) {
    if (context) {
      await customizeController({
        requestContext,
        context,
        appConfigs,
        config: global.config,
        oktaSignIn: global.oktaSignIn,
      });
    }
  });

  widget.on('afterError', function (context: any, error: any) {
    try {
      // in classic engine, password requirements not met error contains one extra dot (.) at the end
      const isFormValidationError =
        error?.xhr?.responseJSON?.errorCauses?.[0]?.['errorSummary']?.indexOf(ERROR_MESSAGES['password.passwordRequirementsNotMet']) > -1;

      const sysErrorRegex = /^5\d\d$/;
      const errorMessage = error.message;
      const isAuthApiError = context && error && error.name === 'AuthApiError';
      const isE0000004 = error?.xhr?.responseJSON?.errorCode === 'E0000004';
      const isNotSysErr = error?.statusCode !== 429 && !sysErrorRegex.test(error.statusCode);
      if (isAuthApiError && isE0000004 && context.controller === 'primary-auth') {
        AmplitudeUtils.logCustomEvent('InvalidCredentials', { errorMessage });
      }
      else if (isAuthApiError && isNotSysErr) {
        AmplitudeUtils.logCustomEvent('UserError', { errorMessage, ...context, error });
      }
      else if (!isFormValidationError) {
        AmplitudeUtils.logCustomEvent('SystemError', { errorMessage, ...context, error });
      }
    }
    catch (e) {
      // do nothing on errors
    }
  });
}

export default aosw;
