import BaseEnrollPhoneFactorCustomizer from '../base';
//TODO: needed for OIE Factor setup
/*
import config from '../../../config';
import { footerButton } from '../../Footer';
*/
export default class EnrollAuthenticatorCustomizer extends BaseEnrollPhoneFactorCustomizer {
  headerDescription = 'A code was sent to your phone. Enter the code below to verify.';
  async shouldCustomize() {
    //TODO: needed for OIE Factor setup
    /*
    let buttonFound = false;
    let retries = 0;
    const maxRetries = 10;
    function waitForElement() {
      const $element = jQueryCourage('.fe_c_button--secondary');
      if ($element.length > 0 && !buttonFound) {
        $element.trigger('click');
        buttonFound = true;
      }
      else if (!buttonFound && retries < maxRetries) {
        retries++;
        setTimeout(waitForElement, 1000);
      }
    }

    const queryParams = new URLSearchParams(window.location.search);
    const manageFactorsValue = queryParams.get('manageFactor');
    const clientId = queryParams.get('clientId');
    const factorType = queryParams.get('factorType');
    if (config.patientMFAEnabled
      && manageFactorsValue
      && clientId
      && athenaConfig?.clientIdMappingManageProfileRedirect?.[clientIdParam]!== 'undefined'
      && factorType === 'PHONE_FACTOR'
    ) {
      waitForElement();
      jQueryCourage('body > div.back-to-settings').remove();
      footerButton();
    }
    */

    return 'enroll-authenticator' === this.context?.formName && this.context.authenticatorKey === 'phone_number';
  }
}
