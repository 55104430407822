import { getOktaWidgetModel } from '../../utils/widget-facade';
import athenaConfig from '../../config';
import BaseEnrollPhoneFactorCustomizer from './base';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { toPascalCase } from '../../utils/string-utils';
import PhoneInputSectionClassic from '../../components/enroll-phone/PhoneInputSectionClassic';
import { footerButton } from '../ManageFactorFooter';
import { PATIENT_MFA_FACTOR_SETUP_CUSTOM_EVENTS } from '../../constants';

const { classic } = athenaConfig;

const MFA_ENROLL_CLASS_AMPLITUDE_EVENT: Record<string, string> = {
  'enroll-call': 'VoiceCall',
  'enroll-sms': 'SMS',
};

export default class EnrollPhoneFactorCustomizer extends BaseEnrollPhoneFactorCustomizer {
  static manageFactorValue: string | null = null;
  static clientId: string | null = null;

  async shouldCustomize() {
    return (
      classic &&
      //Okta controller uses a function for classname for this specific case
      typeof this.oktaSignIn.router?.controller?.className === 'function' &&
      [ 'enroll-call', 'enroll-sms' ].includes(this.oktaSignIn.router?.controller?.className())
    );
  }

  async customize() {

    if (athenaConfig.patientMFAEnabled
      && EnrollPhoneFactorCustomizer.manageFactorValue === 'true'
      && EnrollPhoneFactorCustomizer.clientId
      && athenaConfig?.clientIdMappingManageProfileRedirect?.[EnrollPhoneFactorCustomizer.clientId] !== 'undefined') {
      document.removeEventListener(
        PATIENT_MFA_FACTOR_SETUP_CUSTOM_EVENTS.manageFactorWorkflow,
        this.manageFactorEvent as EventListener
      );
      jQueryCourage('body > div.back-to-settings').remove();
      window.addEventListener('load', function () {
        footerButton(EnrollPhoneFactorCustomizer.clientId);
      });

      const verifyButton = jQueryCourage('input.button-primary[value="Verify"]');
      verifyButton.on('click', () => {
        const verifyButtonClicked = new CustomEvent(PATIENT_MFA_FACTOR_SETUP_CUSTOM_EVENTS.verifyButtonClicked, {
          detail: {
            verifyButtonClickedPhoneManageFactor: 'true',
            clientId: EnrollPhoneFactorCustomizer.clientId,
          },
        });

        document.dispatchEvent(verifyButtonClicked);
      });
    }

    const controllerClass = this.oktaSignIn.router?.controller?.className();
    if (controllerClass) {
      AmplitudeUtils.logMfaEnrollEvent(`${MFA_ENROLL_CLASS_AMPLITUDE_EVENT[controllerClass]}::Landing`);

      this.primaryButtonOnClick = (buttonText: string) => {
        buttonText = buttonText ? toPascalCase(buttonText) : 'Verify';
        AmplitudeUtils.logMfaEnrollEvent(`${MFA_ENROLL_CLASS_AMPLITUDE_EVENT[controllerClass]}::${buttonText}ButtonClick`);
      };

      this.secondaryButtonOnClick = (buttonText: string) => {
        AmplitudeUtils.logMfaEnrollEvent(`${MFA_ENROLL_CLASS_AMPLITUDE_EVENT[controllerClass]}::${toPascalCase(buttonText)}ButtonClick`);
      };
    }

    await super.customize();

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const widgetModel = getOktaWidgetModel();
    widgetModel.on('change:ableToResend', function (_: any, ableToResend: boolean) {
      self.eventEmitter.emitEventToComponent({
        componentName: PhoneInputSectionClassic.displayName as string,
        eventName: 'able_to_resend_otp',
        eventPayload: { ableToResend },
      });
    });
    widgetModel.on('change:enrolled', function () {
      self.eventEmitter.emitEventToComponent({
        componentName: PhoneInputSectionClassic.displayName as string,
        eventName: 'enrolled_phone_changed',
      });
    });
  }

  protected manageFactorEvent = (e: CustomEvent) => {
    EnrollPhoneFactorCustomizer.manageFactorValue = e.detail.manageFactorValue;
    EnrollPhoneFactorCustomizer.clientId = e.detail.clientId;
  };
  protected forgifyController() {
    super.forgifyController();
    this.hidePhoneSectionButtons();
  }

  private hidePhoneSectionButtons() {
    // These buttons are already forgified by the custom component for this controller, so simply hide them from here
    jQueryCourage('a.call-request-button,a.sms-request-button').each((index, element) => this.hideComponent(element));
  }
}

document.addEventListener(
  PATIENT_MFA_FACTOR_SETUP_CUSTOM_EVENTS.manageFactorWorkflow,
  (e) => {
    EnrollPhoneFactorCustomizer.manageFactorValue = (e as CustomEvent).detail.manageFactorValue;
    EnrollPhoneFactorCustomizer.clientId = (e as CustomEvent).detail.clientId;
  }
);

