export const APP_TYPES = {
  ATHENANET_USER: 'athena-net-user',
  ATHENISTA: 'athenista',
  ADJACENT_SERVICES_USERS: 'adjacent-services-users',
  PATIENT: 'patient',
};


export const ERROR_KEYS = {
  GENERIC_INTERNAL_ERROR: 'general.internal.error',
};

export const ERROR_MESSAGES: Record<string, string> = {
  'symantec.security.code.validation.failed': 'Your Security Code is invalid.',
  'symantec.credential.expired.error':
    'Bad credential state or credential is expired. Please contact your practice administrator.',
  'symantec.credential.has.been.disabled':
    'This credential has been disabled. Please contact your practice administrator.',
  'symantec.credential.has.been.locked': 'This credential has been locked. Please contact your practice administrator.',
  'symantec.credential.is.not.yet.enabled':
    'This credential is not yet enabled. Please contact your practice administrator.',
  'symantec.credential.is.not.active': 'This credential is not active. Please contact your practice administrator.',
  [ERROR_KEYS.GENERIC_INTERNAL_ERROR]: "We're sorry, something went wrong. Please try again later.",
  'symantec.prompt.internal.error':
    "We're having technical problems right now, and we're working onto fix them. We apologize for this inconvenience. Please try logging in again.",
  'error.field.empty': 'This field cannot be left blank',
};

export const INFO_BANNER_MESSAGE_MAP: Record<string, any> = {
  LOGOUT: {
    header: 'You are now logged out',
    description: 'You have successfully ended your athenaOne login session.',
  },
  TIMEDOUT: {
    header: 'Session Timed Out',
    description: 'Your session has timed out. Please log in to resume this session.',
  },
  TIMEOUT: {
    header: 'Session Timed Out',
    description: 'Your session has timed out. Please log in to resume this session.',
  },
  REFRESHTIMEOUT: {
    header: 'Refresh Timed Out',
    description: 'Your session has timed out. Please log in to start a new session.',
  },
};

export const INFO_BANNER_MESSAGE_MAP_V2: Record<string, any> = {
  LOGOUT: {
    DEFAULT: {
      header: 'You are now logged out',
      description: 'You have successfully ended your athenaOne login session.',
      alertType: 'success',
    },
  },
  TIMEDOUT: {
    DEFAULT: {
      header: 'Session Timed Out',
      description: 'Your session has timed out. Please log in to resume this session.',
      alertType: 'info',
    },
  },
  REFRESHTIMEOUT: {
    DEFAULT: {
      header: 'Refresh Timed Out',
      description: 'Your session has timed out. Please log in to start a new session.',
      alertType: 'info',
    },
  },
  CONCIERGEMODE: {
    DEFAULT: {
      header: 'Session Locked',
      description: 'Your session has been locked while the patient creates an account using your device. If the patient registration workflow does not open automatically in a new tab, you may need to disable your browser\'s pop-up blocker. Please log in to resume this session.',
      alertType: 'info',
    },
  },
  SESSIONLOCKED: {
    DEFAULT: {
      header: 'Multiple User Sessions',
      description: 'Your session has been locked because {0} logged in through the same browser. Please log in to resume this session.',
      alertType: 'info',
    },
  },
  ERROR: {
    DEFAULT: {
      header: 'Login Failed',
      description: 'We\'re sorry, something went wrong. Please try again later.',
      alertType: 'critical',
    },
    ACCOUNTEXPIRED: {
      header: 'Account Blocked',
      description: 'Your account is blocked. Please contact your system administrator to regain access.',
      alertType: 'critical',
    },
    ACCOUNTBLOCKEDSHELLUSER: {
      header: '',
      description: 'You cannot log in with this username. Please try a different username.',
      alertType: 'critical',
    },
    ACCOUNTBLOCKED: {
      header: 'Account Blocked',
      description: 'Your account is blocked. Please contact your system administrator to regain access.',
      alertType: 'critical',
    },
    TIMEOFDAYRESTRICTED: {
      header: '',
      description: '{0}',
      alertType: 'info',
    },
    UNKNOWNIDENTITYPROVIDER: {
      header: 'Single Sign-On Failed',
      description: 'You logged in using an identity provider {0} that is not configured for single sign-on. Please contact your system administrator to confirm your identity provider.',
      alertType: 'attention',
    },
    UNKNOWNANETUSER: {
      header: 'No Associated Account',
      description: 'Your username {0} is not associated with an athenaOne account in this environment. Please contact your system administrator for assistance.',
      alertType: 'attention',
    },
    WRONGUSER: {
      header: 'Session Lost',
      description: 'The current session cannot be resumed. Please log in again to start a new session.',
      alertType: 'info',
    },
    ACCOUNTMAPPINGERRORMULTIPLE: {
      header: 'Multiple Accounts',
      description: 'Your external username {0} is mapped to multiple athenaOne accounts {1} but can only be mapped to one.  Please contact your system administrator for assistance.',
      alertType: 'attention',
    },
    ACCOUNTMAPPINGERROREMPTY: {
      header: 'No Associated Account',
      description: 'Your external username {0} is not associated with an athenaOne account. Please contact your system administrator for assistance.',
      alertType: 'attention',
    },
    AUTOCREATIONERROR: {
      header: 'Auto-Provisioning Failed',
      description: 'Could not create an athenaOne account for single sign-on.',
      alertType: 'attention',
    },
    BADTOKEN: {
      header: '',
      description: '{0}',
      alertType: 'critical',
    },
    TRANSIENTERROR: {
      header: 'Login Interrupted',
      description: 'We\'re sorry, something went wrong. Please restart your browser and try again.',
      alertType: 'critical',
    },
    ACCESSDENIED: {
      header: 'Access Denied',
      description: 'You are logged in as someone who does not have access to athenaOne (e.g., a patient). Please log out and try again.',
      alertType: 'critical',
    },
    TEMPORARILYUNAVAILABLE: {
      header: 'Login Unavailable',
      description: 'Login service could not be reached. Please try again later.',
      alertType: 'attention',
    },
    INVALIDIDENTITYPROVIDER: {
      header: 'Single Sign-On Failed',
      description: 'You clicked a single sign-on link for an identity provider that does not exist or is not configured for single sign-on. Please contact your system administrator to update the single sign-on link.',
      alertType: 'attention',
    },
    TOOMANYREQUESTS: {
      header: 'Too Many Requests',
      description: 'Service is overloaded. Please try again later.',
      alertType: 'attention',
    },
    NOPRACTICE: {
      header: 'No Practice Access',
      description: 'Your account is not associated with any practices. Please contact your system administrator for assistance.',
      alertType: 'attention',
    },
    TERMINALNOTALLOWED: {
      header: 'IP Address Blocked',
      description: 'You are not allowed to log in from terminal {0}.',
      alertType: 'attention',
    },
    ACCOUNTBLOCKEDMAXLOGINS: {
      header: 'Account Blocked',
      description: 'You have exceeded the maximum number of login attempts. Please contact your system administrator to regain access.',
      alertType: 'critical',
    },
  },
};

export const NON_FUZZY_ANET_USER_DOMAIN = 'managedidentity.platform.athenahealth.com';
export const FUZZY_ENV_MAPPING: Record<string, any> = {
  preview: {
    userNamespace: '.preview.athenahealth.com',
    anetUserDomain: `preview.${NON_FUZZY_ANET_USER_DOMAIN}`,
  },
  slsdemo: {
    userNamespace: '.salesdemo.athenahealth.com',
    anetUserDomain: `salesdemo.${NON_FUZZY_ANET_USER_DOMAIN}`,
  },
  clienttrain: {
    userNamespace: '.clienttrain.athenahealth.com',
    anetUserDomain: `clienttrain.${NON_FUZZY_ANET_USER_DOMAIN}`,
  },
  sandbox: {
    userNamespace: '.sandbox.athenahealth.com',
    anetUserDomain: `sandbox.${NON_FUZZY_ANET_USER_DOMAIN}`,
  },
  clientsandbox: {
    userNamespace: '.clientsandbox.athenahealth.com',
    anetUserDomain: `clientsandbox.${NON_FUZZY_ANET_USER_DOMAIN}`,
  },
};

export const ANET_USER_PREFIX_SUPPORT_ENV_MAP: Record<string, string> = {
  p: 'preview',
  d: 'slsdemo',
  c: 'clienttrain',
  s: 'sandbox',
  cs: 'clientsandbox',
};

export const OHSW_EVENT_PREFIX = 'oktaHostedLogin::';
export const PASSWORD_EXPIRED_EVENT_PREFIX = 'PasswordExpired::';
export const ERROR_PAGE_EVENT_PREFIX = 'ErrorPage::';
export const MFA_ENROLL_EVENT_PREFIX = 'MFAEnroll::';
export const MFA_CHALLENGE_EVENT_PREFIX = 'MFAChallenge::';

export const CLIENTID_ATHENAONE_HOST_MAPPING: Record<string, string> = {
  '0oaet0rfjNzyKCiQQ296': 'athenanet.athenahealth.com',
  '0oa16evw7trKLV80X297': 'preview.athenahealth.com',
  '0oa1g3pp3dNEa1Rhm297': 'salesdemo.athenahealth.com',
  '0oa1g3qnz9iqZWIjR297': 'clienttrain.athenahealth.com',
  '0oa1g3qt4lLFlpa7d297': 'sandbox.athenahealth.com',
  '0oa4zsgxk7LR1gMAo297': 'clientsandbox.athenahealth.com',
};

export const PROVIDER_FACTOR_TYPE_APP_MAPPING: Record<string, any> = {
  OKTA: {
    'token:software:totp': 'Okta Verify App',
    push: 'Okta Verify App',
  },
  GOOGLE: {
    'token:software:totp': 'Google Authenticator',
  },
};
function _getManualInstructionsText(appName: string): string {
  return `Open the ${appName} on your mobile device and follow the app's instructions to add an account. For Account Type select "Other", then choose "Enter Key Manually". Provide any label in the Account Name field and enter the following in the Key field:`;
}
export const STEPS_TO_FINISH_SETUP_TEXT = 'Follow the steps below to finish setup';
export const STEPS_TO_FINISH_PHONE_SETUP_TEXT = 'Receive a code via phone call to finish setup';
export const SMS_SETUP_TEXT = 'Receive a code via SMS to finish setup';
export const CONTACT_ADMIN_TEXT = 'Please contact your administrator if you require assistance setting up or removing a factor.';
const BACK_TO_FACTORS_TEXT = 'Choose a different factor';
const PRIMARY_AUTH_SUBMIT_TEXT = 'Log In';
const OKTA_VERIFY_APP = 'Okta Verify App';
const GOOGLE_AUTHENTICATOR_MANUAL_INSTRUCTIONS_TEXT = _getManualInstructionsText('Google Authenticator');
const OKTA_VERIFY_MANUAL_INSTRUCTIONS_TEXT = _getManualInstructionsText('Okta Verify App');
const SEND_CODE_TEXT = 'Send Code';

export const I18N_MAPPING: Record<string, any> = {
  en: {
    'enroll.choices.setup': 'Set Up',
    'enroll.choices.list.enrolled': 'Your factors',
    'enroll.choices.list.optional': 'Other available factors',
    'enroll.choices.optional': 'You can set up more factors or click Finish to continue with your current factors.',
    'errors.E0000047': 'Service overloaded, please try again later.',
    'enroll.totp.setupApp': "Open the {0} on your mobile device and follow the app's instructions to add an account.",
    'enroll.totp.cannotScanBarcode': "Can't scan QR code?",
    'enroll.totp.setupManually': 'Set up manually without push notification',
    'factor.oktaVerifyPush': OKTA_VERIFY_APP,
    'factor.push.description': 'Use a push notification sent to the Okta Verify mobile app.',
    'factor.totpSoft.oktaVerify': OKTA_VERIFY_APP,
    'mfa.backToFactors': BACK_TO_FACTORS_TEXT,
    'mfa.phoneNumber.placeholder': 'Phone Number',
    'mfa.scanBarcode': 'Scan QR code',
    goback: 'Back to Log In',
    'primaryauth.submit': PRIMARY_AUTH_SUBMIT_TEXT,
    'oie.primaryauth.submit': PRIMARY_AUTH_SUBMIT_TEXT,
    'errors.E0000004': 'Invalid Username or Password',
    'errors.E0000119': 'Your account is locked. Please contact your administrator.',
    'enroll.choices.description.generic': 'Your organization requires you to set up at least one factor below. The factors you choose are how you will verify your identity for future logins. We recommend setting up at least two factors using different devices so you have a backup verification method.',
    'enroll.call.setup': STEPS_TO_FINISH_PHONE_SETUP_TEXT,
    'enroll.sms.setup': SMS_SETUP_TEXT,
    'oie.phone.enroll.call.subtitle': STEPS_TO_FINISH_PHONE_SETUP_TEXT,
    'oie.phone.enroll.sms.subtitle': SMS_SETUP_TEXT,
    'oie.phone.sms.primaryButton': SEND_CODE_TEXT,
    'mfa.sendCode': SEND_CODE_TEXT,
    'mfa.resendCode': 'Re-Send Code',
    'oie.phone.call.primaryButton': 'Call',
    'oie.enroll.switch.authenticator': BACK_TO_FACTORS_TEXT,
    'oie.enroll.google_authenticator.manualSetupInstructions': GOOGLE_AUTHENTICATOR_MANUAL_INSTRUCTIONS_TEXT,
    'enroll.totp.manualSetupInstructions.generic': GOOGLE_AUTHENTICATOR_MANUAL_INSTRUCTIONS_TEXT,
    'enroll.totp.sharedSecretInstructions.generic': OKTA_VERIFY_MANUAL_INSTRUCTIONS_TEXT,
    'oie.okta_verify.label': OKTA_VERIFY_APP,
    'oie.verification.switch.authenticator': BACK_TO_FACTORS_TEXT,
  },
  es: {
    'errors.E0000047': 'El servicio esta sobrecargado. Inténtalo de nuevo más tarde.',
  },
};

export const INVALID_SESSION_MESSAGE = 'Invalid session';
export const INVALID_STATE_TOKEN_ERROR_CODE = 'E0000011';
export const SESSION_EXPIRED_ERROR_MESSAGE = 'You have been logged out due to inactivity. Refresh or return to the sign in screen.';

export const OKTA_MFA_FACTOR_AMPLITUDE_EVENTS : Record<string, string> = {
  'Okta Verify App': 'OktaVerify',
  'Google Authenticator': 'GoogleAuthenticator',
  'SMS Authentication': 'SMS',
  'Voice Call Authentication': 'VoiceCall',
};

export const TOTP_PROVIDER_AMPLITUDE_EVENT : Record<string, string> = {
  OKTA: 'OktaVerify',
  GOOGLE: 'GoogleAuthenticator',
};

export const PATIENT_MFA_FACTOR_SETUP_CUSTOM_EVENTS : Record<string, string> = {
  saveButtonClicked: 'saveButtonClicked',
  verifyButtonClicked: 'verifyButtonClicked',
  manageFactorWorkflow: 'manageFactorWorkflow',
};

export const ENROLLMENT_CHANNEL_DESCRIPTION = 'Make sure you can access the text on your mobile device.';

export const AUTH_SERVER_ID_ANET_SIGNED_JWT_KEY_MAP: any = {
  auset0ja9xZ2Hniep296: 'prod',             // primary
  ausu5cwvrZnWYasjS296: 'supportEnvPHI',    // Support PHI
  aus1eumlr5MD3rYcn297: 'supportEnvNonPHI', // Support Non PHI
};
